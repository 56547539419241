import React, {
  useRef,
  // useCallback,
  useState,
  useEffect,
} from 'react';
import ReactPlayer from 'react-player';

import './MediaPlayer.css';
// import playImage from '../img/play.png';
// import pauseImage from '../img/pause.png';

export const videoUrl = `https://firebasestorage.googleapis.com/v0/b/maisiesmelody.appspot.com/o/MAISIES_MELODY_FINAL.mp4?alt=media&token=82411072-413f-4008-b239-5db5e07c57e2`;

export const audioUrl = `https://firebasestorage.googleapis.com/v0/b/maisiesmelody.appspot.com/o/maisies_melody_audio_v1.mp3?alt=media&token=a5346913-2871-42be-b085-0be39ecf2d73`;

export function MediaPlayer({
  type,
  active,
}: {
  type: 'audio' | 'video';
  active: boolean;
}) {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoPlayerRef = useRef<any>();
  // const togglePlaying = useCallback(() => {
  //   setVideoPlaying((playing) => !playing);
  // }, []);
  const url = type === `audio` ? audioUrl : videoUrl;
  const containerClassName =
    type === `audio` ? `audio-player-container` : `video-player-container`;
  const className = type === `audio` ? `audio-player` : `video-player`;

  useEffect(() => {
    if (!active) {
      setVideoPlaying(false);
    }
  }, [active]);

  return (
    <div>
      <div className={containerClassName}>
        <ReactPlayer
          className={className}
          onStart={() => {
            setVideoPlaying(true);
          }}
          onPlay={() => {
            setVideoPlaying(true);
          }}
          onPause={() => {
            setVideoPlaying(false);
          }}
          onEnded={() => {
            setVideoPlaying(false);
          }}
          // onProgress={(args) => {
          //   console.log(`** onProgress **`, args);
          // }}
          // onDuration={(args) => {
          //   console.log(`** onDuration **`, args);
          // }}
          // onError={(args) => {
          //   console.log(`** onError **`, args);
          // }}
          controls
          config={{
            file: {
              attributes: {
                controlsList: `nodownload`,
              },
            },
          }}
          playing={videoPlaying}
          ref={videoPlayerRef}
          url={url}
          width="100%"
          height="100%"
        />
      </div>
      {/* <div className="video-player-controls">
        <button className="video-player-button" onClick={togglePlaying}>
          <img
            src={videoPlaying ? pauseImage : playImage}
            className="video-player-button-image"
            alt={videoPlaying ? "Pause" : "Play"}
          />
        </button>
      </div> */}
    </div>
  );
  // gs://maisies-song.appspot.com/maisies_melody_v1.mov
  //   return <ReactPlayer url="https://www.youtube.com/watch?v=ysz5S6PUM-U" />;
  //   return <div>MEDIA PLAYER</div>;
}
