import React from 'react';
import '@fontsource/lato';

import { MediaPlayer, videoUrl, audioUrl } from '../components/MediaPlayer';

import '../css/home.css';

import headerImage from '../img/maisies-melody.svg';
import animalNotesImage from '../img/animal-notes.svg';
import wwfLogo from '../img/wwf-logo.png';
import catImage from '../img/cat.svg';

export default function Home() {
  return (
    <div className="App">
      <div className="App-header-wrapper">
        <div className="App-header">
          <img
            src={headerImage}
            className="App-header-image"
            alt="Maisie's Melody"
          />
        </div>
        <div className="App-banner">
          <img
            src={animalNotesImage}
            className="App-banner-image"
            alt="Animal Notes"
          />
        </div>
      </div>
      <div className="App-content">
        <div className="descr-and-media-container">
          <div className="description">
            <div className="descr-block block-1">
              <p>
                Our Beautiful, Caring, Funny, Kind, Loving daughter Maisie was
                stolen away from us and died at the age of 9 in October 2021, 4
                days after catching Covid. She had been totally well and was
                absolutely full of life and enthusiasm for everything.
              </p>
            </div>
            <div className="descr-block block-2">
              <p>
                Maisie had a natural musical talent. After just two or three
                piano lessons she wrote her own short piece of music, which she
                played all the time and I only ever had one video of her playing
                it. After she died, I heard it in my head on repeat every day
                but with a full accompaniment, so I wanted to get it made into
                something much more.
              </p>
            </div>
            <div className="descr-block block-3">
              <p>
                We only had 1 video of Maisie playing. With the help of Lucie
                Treacher and Oliver Weder, some very kind people at Spitfire
                Audio, I very proudly present: Maisie's Melody (please listen on
                speakers or headphones for the full effect)
              </p>
              <p>- Rob</p>
            </div>
          </div>
          <div className="App-media-player-container">
            <div className="App-media-video">
              <MediaPlayer type="video" active />
              <h3 className="media-title">Video</h3>
              <p className="media-text">
                Maisie playing her original melody with orchestral accompaniment
              </p>
            </div>
            <div className="App-media-audio">
              <div className="App-media-audio-inner">
                <MediaPlayer type="audio" active />
                <h3 className="media-title">Audio</h3>
                <p className="media-text">
                  Extended track with full orchestral accompaniment
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="animal-image-container">
          <img src={catImage} className="animal-image" alt="Cat" />
        </div>

        <div className="donation">
          <div className="donation-block-1">
            <p>
              Maisie loved animals and wanted to be a vet. Sadly she never got
              to see that dream through.
            </p>
            <p>
              If you can, please help Maisie&apos;s dream of helping animals
              live on, by donating through her ‘in memory’ page for World Wide
              Fund for Nature.
            </p>
          </div>
          <div className="donation-block-2">
            <div className="donation-link">
              <img
                src={wwfLogo}
                className="wwf-image"
                alt="World Wide Fund Logo"
              />
              <a
                className="wwf-link"
                href="https://giveinmemory.wwf.org.uk/In-Memory/Maisie-Woolley"
                target="_blank"
                rel="noreferrer"
              >
                DONATE
              </a>
            </div>
          </div>
        </div>

        <div className="copyright-notice">
          <p>All video and audio copyright ©️ Robert Woolley 2021</p>
        </div>
      </div>
    </div>
  );
}
